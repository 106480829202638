<template>
  <div class="d-flex flex-column px-4 py-4">
    <h2 class="mb-4 cursive-font">Assign VAs</h2>

    <table-with-filters
      ref="assignmentTable"
      :options="tableOptions"
      :customColumns="customColumns"
    >
      <template v-slot:client-name="{ item }">
        <div>
          <v-avatar size="30px">
            <img :src="resolveProfilePic(item.client)" alt="" />
          </v-avatar>
          <span class="ml-2">{{ item.client.username }}</span>
        </div>
      </template>

      <template v-slot:sub-status="{ item }">
        <span class="text-capitalize">
          {{ item.subscription.status || "Not subscribed" }}
        </span>
      </template>

      <template v-slot:va-name="{ item }">
        <div v-show="item.va.username">
          <v-avatar size="30px">
            <img :src="resolveProfilePic(item.va)" alt="" />
          </v-avatar>
          <span class="ml-2">{{ item.va.username }}</span>
        </div>
        <span v-show="!item.va.username">No VA</span>
      </template>

      <template v-slot:manage-btn="{ item }">
        <v-btn color="primary" small @click="setEditTarget(item)">
          Manage
        </v-btn>
      </template>
    </table-with-filters>

    <edit-assignment-dialog
      v-if="isEditing"
      @update-table="triggerTableUpdate"
    ></edit-assignment-dialog>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import { resolveProfilePic } from "@/helpers";
import { useAssignment } from "@/composables/assignment/assignment.js";

import TableWithFilters from "@/components/common/TableWithFilters.vue";
import EditAssignmentDialog from "@/components/assignment/EditAssignmentDialog.vue";

export default {
  name: "ManageAssignment",
  components: { EditAssignmentDialog, TableWithFilters },
  setup() {
    const tableHeaders = [
      { text: "Client", value: "clientName", sortable: false },
      { text: "Email", value: "client.email", sortable: false },
      { text: "Subscription Status", value: "subStatus", sortable: false },
      { text: "Assigned VA", value: "vaName", sortable: false },
      { text: "Manage", value: "manageBtn", sortable: false },
    ];

    const customColumns = [
      { slotName: "client-name", itemValue: "clientName" },
      { slotName: "sub-status", itemValue: "subStatus" },
      { slotName: "va-name", itemValue: "vaName" },
      { slotName: "manage-btn", itemValue: "manageBtn" },
    ];

    const filterFields = [
      { label: "Client Name", value: "username", component: "v-text-field" },
      { label: "Client Email", value: "email", component: "v-text-field" },
    ];

    const assignmentTable = ref(null);
    const { setEditTarget, isEditing } = useAssignment();

    function triggerTableUpdate() {
      assignmentTable.value.fetchTableData();
    }

    return {
      assignmentTable,
      triggerTableUpdate,
      setEditTarget,
      isEditing,

      resolveProfilePic,

      tableOptions: {
        endpoint: "/admin/assignments",
        headers: tableHeaders,
        filtersTitle: "Filter Clients",
        filterFields,
      },
      customColumns,
    };
  },
};
</script>

<style>
.v-data-footer__pagination {
  margin-left: auto !important;
}

.v-data-footer__select {
  display: none;
}
</style>
