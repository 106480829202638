var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column px-4 py-4"},[_c('h2',{staticClass:"mb-4 cursive-font"},[_vm._v("Assign VAs")]),_c('table-with-filters',{ref:"assignmentTable",attrs:{"options":_vm.tableOptions,"customColumns":_vm.customColumns},scopedSlots:_vm._u([{key:"client-name",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-avatar',{attrs:{"size":"30px"}},[_c('img',{attrs:{"src":_vm.resolveProfilePic(item.client),"alt":""}})]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.client.username))])],1)]}},{key:"sub-status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(item.subscription.status || "Not subscribed")+" ")])]}},{key:"va-name",fn:function(ref){
var item = ref.item;
return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.va.username),expression:"item.va.username"}]},[_c('v-avatar',{attrs:{"size":"30px"}},[_c('img',{attrs:{"src":_vm.resolveProfilePic(item.va),"alt":""}})]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.va.username))])],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!item.va.username),expression:"!item.va.username"}]},[_vm._v("No VA")])]}},{key:"manage-btn",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.setEditTarget(item)}}},[_vm._v(" Manage ")])]}}])}),(_vm.isEditing)?_c('edit-assignment-dialog',{on:{"update-table":_vm.triggerTableUpdate}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }