import { reactive, computed, readonly } from "@vue/composition-api";

const state = reactive({
  editTarget: {},
});

const isEditing = computed(() => {
  if (Object.keys(state.editTarget).length) return true;
  return false;
});

const setEditTarget = (val) => (state.editTarget = val);

export function useAssignment() {
  return {
    state: readonly(state),
    setEditTarget,

    isEditing,
  };
}
