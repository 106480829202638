<template>
  <v-dialog v-model="isEditing" persistent>
    <v-card
      :loading="state.isSubmitting"
      :disabled="state.isSubmitting"
      class="bg-light"
    >
      <v-card-title>
        <span>
          Assign Virtual Assitant To
          {{ assignmentState.editTarget.client.username }}</span
        >
        <v-btn @click="setEditTarget({})" class="ml-auto">Close</v-btn>
      </v-card-title>

      <v-card-text>
        Currently assigned to :
        <b>{{ assignmentState.editTarget.va.username || "None" }}</b>
      </v-card-text>

      <hr />

      <v-card-text class="col-md-6">
        <v-card-title>Filter Virtual Assistants</v-card-title>

        <div class="d-flex flex-row flex-wrap">
          <v-card-text class="col-6">
            <v-text-field
              v-model="state.filters.username"
              label="VA Name"
              dense
              hide-details
              outlined
            >
            </v-text-field>
          </v-card-text>

          <v-card-text class="col-6">
            <v-text-field
              v-model="state.filters.email"
              label="VA Email"
              dense
              hide-details
              outlined
            ></v-text-field>
          </v-card-text>
        </div>

        <v-card-actions>
          <v-btn @click="applyFilters" color="primary">Update Table</v-btn>
          <v-btn @click="clearFilters">Reset</v-btn>
        </v-card-actions>
      </v-card-text>

      <hr />

      <v-card-text>
        <v-data-table
          :headers="tableHeaders"
          :items="state.tableData"
          :loading="state.isLoading"
          :page.sync="state.page"
          :server-items-length="state.totalItems"
          :items-per-page.sync="state.itemsPerPage"
          @update:page="handlePageChange"
          v-model="state.selected"
          single-select
          show-select
          no-data-text="No Data"
        >
          <template v-slot:item.vaName="{ item }">
            <div v-show="item.username">
              <v-avatar size="30px">
                <img :src="resolveProfilePic(item)" alt="" />
              </v-avatar>
              <span class="ml-2">{{ item.username }}</span>
            </div>

            <span v-show="!item.username">No VA</span>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-btn
          @click="updateAssignment"
          color="primary"
          :loading="state.isSubmitting"
          >Confirm</v-btn
        >
        <v-btn @click="setEditTarget({})">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "@axios";
import { reactive, onMounted } from "@vue/composition-api";
import { resolveProfilePic } from "@/helpers";
import { useAssignment } from "@/composables/assignment/assignment.js";

export default {
  name: "VirtualAssistants",
  setup(_, { emit }) {
    const tableHeaders = [
      {
        text: "Name",
        value: "vaName",
      },
    ];

    const {
      setEditTarget,
      isEditing,
      state: assignmentState,
    } = useAssignment();

    const state = reactive({
      tableData: [],
      isLoading: false,
      isEditing: false,

      selected: [],
      isSubmitting: false,

      itemsPerPage: 10,
      totalItems: 0,
      page: 1,
      editTarget: {},

      filters: {
        username: "",
        email: "",
      },
    });

    function handlePageChange(event) {
      state.page = parseInt(event);
      fetchTableData();
    }

    function applyFilters() {
      state.page = 1;
      fetchTableData();
    }

    function clearFilters() {
      state.page = 1;
      state.filters.username = "";
      state.filters.email = "";
      fetchTableData();
    }

    onMounted(() => {
      state.selected = [assignmentState.editTarget.va];
      fetchTableData();
    });

    async function fetchTableData() {
      try {
        state.isLoading = true;
        const { data } = await axios.get("/admin/virtual-assistants", {
          params: { page: state.page, ...state.filters },
        });

        state.tableData = data.results;
        state.page = parseInt(data.page);
        state.itemsPerPage = data.itemsPerPage;
        state.totalItems = data.total;
      } catch (err) {
        this.$confirm("Failed");
      } finally {
        state.isLoading = false;
      }
    }

    async function updateAssignment() {
      var confirmMsg = "";

      if (state.selected.length)
        confirmMsg = `Changing assigned VA to ${state.selected[0].username}. This will clear any previous assignments for the VA.`;
      else
        confirmMsg = `This action will clear the client's current assignment`;

      const confirm = await this.$confirm(confirmMsg, {
        buttonFalseText: "Cancel",
        buttonTrueText: "Confirm",
      });

      if (!confirm) return;

      try {
        state.isSubmitting = true;
        const clientId = assignmentState.editTarget.client.id;
        const vaId = state.selected.length ? state.selected[0].id : null;

        await axios.post("/admin/assign-va", {
          clientId: clientId,
          vaId: vaId,
        });

        setEditTarget({});
        emit("update-table");
        await this.$confirm("Update Successfull", {
          buttonFalseText: "",
          buttonTrueText: "OK",
        });
      } catch (err) {
        await this.$confirm("Update Failed", {
          buttonFalseText: "",
          buttonTrueText: "OK",
        });
      } finally {
        state.isSubmitting = false;
      }
    }

    return {
      state,
      tableHeaders,

      assignmentState,
      isEditing,
      setEditTarget,
      updateAssignment,

      fetchTableData,
      handlePageChange,
      applyFilters,
      clearFilters,

      resolveProfilePic,
    };
  },
};
</script>

<style></style>
